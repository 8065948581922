import { QuoteFinancialAttachment } from '../../constants/common'

export enum QuoteFetchType {
  Refetch = 'refetch',
  DataChangeRefetch = 'dataChangeRefetch',
  Older = 'older',
  NewerData = 'newerData',
}

export interface QuoteUrlOptions {
  dataId?: string
  isLocked?: boolean
  dateFrom?: number
  dateTo?: number
  barsCount?: number
  leftBuffer?: number
}

export interface QuoteFetchOptions extends QuoteUrlOptions {
  aftermarket?: boolean
  premarket?: boolean
  patterns?: boolean
  events?: boolean
  financialAttachments?: QuoteFinancialAttachment[]
}

export const FUTURES_FORCE_REFETCH_TIMEOUT = 60_000
