import { Options, VWAPConfig } from '../overlays/configs/vwap'
import { dateFromUnixTimestamp } from '../utils'
import { MainCalculation } from './main'

const datesAreOnSameDay = (first: Date, second: Date) =>
  first.getFullYear() === second.getFullYear() &&
  first.getMonth() === second.getMonth() &&
  first.getDate() === second.getDate()

type DefaultCalculatedValuesType = {
  vwap: number[]
}

export class VWapCalculation extends MainCalculation<Options, DefaultCalculatedValuesType> {
  static config = VWAPConfig

  calculate() {
    this.calculateFrom(0)
  }

  calculateFrom(index: number, resetOnNewDay = true) {
    const { date, close, low, high, volume } = this.quote
    this._calculatedValues = this.getDefaultCalculatedValues()

    let cumulativePV = 0
    let cumulativeVolume = 0

    for (let i = index; i < date.length; i++) {
      if (resetOnNewDay && i > index) {
        const prevDate = dateFromUnixTimestamp(date[i - 1])
        if (!datesAreOnSameDay(prevDate, dateFromUnixTimestamp(date[i]))) {
          cumulativePV = 0
          cumulativeVolume = 0
        }
      }

      const typicalPrice = (close[i] + low[i] + high[i]) / 3
      const pv = typicalPrice * volume[i]

      cumulativePV += pv
      cumulativeVolume += volume[i]

      this._calculatedValues.vwap[i] = cumulativePV / cumulativeVolume
    }
  }
}
