import { ChartConfigChartPaneElement, RequireByKey } from '../../types/shared'
import { RMICalculation } from '../indicator-calculation/rmi'
import { getParsedIntegersFromPeriodString } from '../utils/helpers'
import { Attrs, RMIConfig } from './configs/rmi'
import Indicator from './indicator'

class RMI extends Indicator<Attrs> {
  static config = RMIConfig

  declare rmiCalculation: RMICalculation
  declare momentum: number
  declare period: number

  static getNumOfBarsBuffer(attrs: RequireByKey<ChartConfigChartPaneElement, 'period'>) {
    // Because of this smoothing, RSI values may differ based on the total calculation period. 250 periods will allow for more smoothing than 30 periods, which will slightly affect RSI values.
    return super.getNumOfBarsBuffer(attrs) + 250
  }

  set(values: Partial<Attrs>) {
    if (typeof values.period === 'string') {
      const [period = 0, momentum = 0] = getParsedIntegersFromPeriodString(values.period)
      super.set({ ...values, period, momentum })
    } else {
      super.set(values)
    }
  }

  compute() {
    if (!this.isComputeNecessary()) return

    this.rmiCalculation = new RMICalculation({
      quote: this.data,
      options: { period: this.period, momentum: this.momentum },
    })

    this.rmiCalculation.calculate()

    this.lastValue = this.rmiCalculation.calculatedValues.rmi.last() ?? null
    const { min, max } = this.getDomainDefaults(this.type)
    this.min = min
    this.max = max
  }

  getValueLabelsAtIndex(index: number) {
    return this.getOversoldOverboughtValueLabelsAtIndex(index, this.rmiCalculation.calculatedValues.rmi)
  }

  renderIndicator(context: CanvasRenderingContext2D) {
    this.renderOversoldOverbought(
      context,
      this.rmiCalculation.calculatedValues.rmi,
      this.momentum + this.period - 1,
      30,
      50,
      70
    )
  }

  getModalConfig() {
    const options = {
      period: {
        type: 'number',
        label: 'Period',
        name: 'period',
        value: this.period ?? 20,
        required: true,
        min: 1,
        max: 999999,
      },
      momentum: {
        type: 'number',
        label: 'Momentum',
        name: 'momentum',
        value: this.momentum ?? 5,
        required: true,
        min: 1,
        max: 999999,
      },
    }

    return {
      title: RMIConfig.label,
      inputs: RMIConfig.inputsOrder.map((item) => options[item]),
      inputsErrorMessages: {
        period: `${options.period.label} must be a whole number between ${options.period.min} and ${options.period.max}`,
        momentum: `${options.momentum.label} must be a whole number between ${options.momentum.min} and ${options.momentum.max}`,
      },
    }
  }

  getIsValid(key: string): boolean {
    switch (key) {
      case 'period':
      case 'momentum':
        return this.getIsNumberInputValid({ key })
      default:
        return false
    }
  }
}

export default RMI
