import * as dateFns from 'date-fns'

import { TIMEFRAME } from '../constants/common'
import { Options, PPCalculationTypeEnum, PPConfig } from '../overlays/configs/pp'
import Utils from '../utils'
import { MainCalculation } from './main'

export enum PivotPeriod {
  day,
  week,
  month,
  year,
}

type DefaultCalculatedValuesType = {
  pivot: number[]
  res1: number[]
  res2: number[]
  res3: number[]
  sup1: number[]
  sup2: number[]
  sup3: number[]
}

type CalculatedPivotPoint = {
  pivot: number
  sup1: number
  sup2: number
  sup3: number
  res1: number
  res2: number
  res3: number
}

type CalculatedValuesForRender = {
  pivotPointsByPeriodKey: {
    [key: string]: CalculatedPivotPoint
  }
}

const FIB_LVL_1 = 0.382
const FIB_LVL_2 = 0.618

export class PPCalculation extends MainCalculation<Options, DefaultCalculatedValuesType> {
  static config = PPConfig
  declare options: Options

  protected _calculatedValuesForRender = { pivotPointsByPeriodKey: {} } as CalculatedValuesForRender

  get calculatedValuesForRender() {
    return this._calculatedValuesForRender
  }

  calculate() {
    const { calculationType } = this.options
    const { close, date, timeframe, low, high } = this.quote

    this._calculatedValues = this.getDefaultCalculatedValues()

    if (close.length === 0) return

    const periodType = getPeriodType(timeframe)
    const periodKeyFormat = getPeriodKeyFormat(periodType)

    let currentPeriodDate = Utils.dateFromUnixTimestamp(date[0])
    let previousPeriodDate = currentPeriodDate
    let previousHigh = Number.MIN_VALUE
    let previousLow = Number.MAX_VALUE
    let previousClose = 0
    let calculatedPivotPoint: CalculatedPivotPoint | undefined

    for (let i = 0; i < close.length; i++) {
      currentPeriodDate = Utils.dateFromUnixTimestamp(date[i])
      const isCurrentPeriodDateSameAsPrevioud = getIsSameDateInPeriod(previousPeriodDate, currentPeriodDate, periodType)

      // if date is in current period date, set previous HLC and skip rest of the loop
      if (isCurrentPeriodDateSameAsPrevioud) {
        previousHigh = Math.max(previousHigh, high[i])
        previousLow = Math.min(previousLow, low[i])
        previousClose = close[i]
        // if there are calculated pp, assign them to current index, it's because at this point in loop
        // calculatedPivotPoints available are calculated from previous period for current period
        if (calculatedPivotPoint !== undefined) this._setCalculatedValues(i, calculatedPivotPoint)
        continue
      }

      // Prev period HLC are ready for use
      // Calculate pivot points

      calculatedPivotPoint = getPivotPoints(previousHigh, previousLow, previousClose, calculationType)
      // assign pp to current period indexes
      this._setCalculatedValues(i, calculatedPivotPoint)

      // because of way we render pp, having to lookup for pp by index isn't suitable
      // it is much better to look for pp for specific period by periodKey,
      // thus we have and aditional format of pp just for rendering purposes
      this._setSupportValues(dateFns.format(currentPeriodDate, periodKeyFormat), calculatedPivotPoint)

      // All calculations/usage of previous period values are done
      // current values are first values from new period,
      // assign previous HLC so first day of new period is included
      previousHigh = high[i]
      previousLow = low[i]
      previousClose = close[i]

      previousPeriodDate = currentPeriodDate
    }
  }

  private _setCalculatedValues(index: number, calculatedPivotPoint: CalculatedPivotPoint) {
    this._calculatedValues.pivot[index] = calculatedPivotPoint.pivot
    this._calculatedValues.res1[index] = calculatedPivotPoint.res1
    this._calculatedValues.res2[index] = calculatedPivotPoint.res2
    this._calculatedValues.res3[index] = calculatedPivotPoint.res3
    this._calculatedValues.sup1[index] = calculatedPivotPoint.sup1
    this._calculatedValues.sup2[index] = calculatedPivotPoint.sup2
    this._calculatedValues.sup3[index] = calculatedPivotPoint.sup3
  }

  private _setSupportValues(periodKey: string, calculatedPivotPoint: CalculatedPivotPoint) {
    this._calculatedValuesForRender.pivotPointsByPeriodKey[periodKey] = { ...calculatedPivotPoint }
  }
}

export function getPeriodType(timeframe: TIMEFRAME) {
  switch (timeframe) {
    case TIMEFRAME.i30:
    case TIMEFRAME.h:
    case TIMEFRAME.h2:
      return PivotPeriod.week
    case TIMEFRAME.h4:
    case TIMEFRAME.d:
      return PivotPeriod.month
    case TIMEFRAME.w:
    case TIMEFRAME.m:
      return PivotPeriod.year
    default:
      return PivotPeriod.day
  }
}

export function getBarsCountInPivotPointsPeriodByTimeframe(timeframe: TIMEFRAME) {
  switch (timeframe) {
    case TIMEFRAME.m:
      return 12 // 1 bar per month, 12 months in year
    case TIMEFRAME.w:
      return 53 // 1 bar per week, 52 weeks in year + 1 for leap year
    case TIMEFRAME.d:
      return 23 // 1 bar per day, 23 days in month (max)
    case TIMEFRAME.h4:
      return 46 // 2 bars per day * 23 days in month (max)
    case TIMEFRAME.h2:
      return 20 // 4 bars per day * 5 days in week
    case TIMEFRAME.h:
      return 35 // 7 bars per day * 5 days in week
    case TIMEFRAME.i30:
      return 65 // 13 bars per day * 5 days in week
    case TIMEFRAME.i15:
      return 26 // 26 bars per day
    case TIMEFRAME.i10:
      return 39 // 39 bars per day
    case TIMEFRAME.i5:
      return 138 // 138 bars per day (with pre-market and after-market)
    case TIMEFRAME.i3:
      return 230 // 230 bars per day (with pre-market and after-market)
    case TIMEFRAME.i2:
      return 345 // 345 bars per day (with pre-market and after-market)
    case TIMEFRAME.i1:
    default:
      return 690 // 690 bars per day (with pre-market and after-market)
  }
}

export function getIsSameDateInPeriod(previousPeriodDate: Date, currentPeriodDate: Date, periodType: PivotPeriod) {
  switch (periodType) {
    case PivotPeriod.day:
      return dateFns.isSameDay(previousPeriodDate, currentPeriodDate)
    case PivotPeriod.week:
      return dateFns.isSameWeek(previousPeriodDate, currentPeriodDate, { weekStartsOn: 1 })
    case PivotPeriod.month:
      return dateFns.isSameMonth(previousPeriodDate, currentPeriodDate)
    case PivotPeriod.year:
      return dateFns.isSameYear(previousPeriodDate, currentPeriodDate)
  }
}

function getPivotPoints(
  previousHigh: number,
  previousLow: number,
  previousClose: number,
  calculationType: PPCalculationTypeEnum
) {
  const pivot = (previousHigh + previousLow + previousClose) / 3
  if (calculationType === PPCalculationTypeEnum.standard) {
    return {
      pivot,
      sup1: 2 * pivot - previousHigh,
      sup2: pivot - (previousHigh - previousLow),
      sup3: previousLow - 2 * (previousHigh - pivot),
      res1: 2 * pivot - previousLow,
      res2: pivot + (previousHigh - previousLow),
      res3: previousHigh + 2 * (pivot - previousLow),
    }
  }

  return {
    pivot,
    sup1: pivot - (previousHigh - previousLow) * FIB_LVL_1,
    sup2: pivot - (previousHigh - previousLow) * FIB_LVL_2,
    sup3: pivot - (previousHigh - previousLow),
    res1: pivot + (previousHigh - previousLow) * FIB_LVL_1,
    res2: pivot + (previousHigh - previousLow) * FIB_LVL_2,
    res3: pivot + (previousHigh - previousLow),
  }
}

export function getPeriodKeyFormat(periodType: PivotPeriod) {
  switch (periodType) {
    case PivotPeriod.day:
      return 'yyyy-MM-dd'
    case PivotPeriod.week:
      return 'yyyy-MM-w'
    case PivotPeriod.month:
      return 'yyyy-MM'
    case PivotPeriod.year:
      return 'yyyy'
  }
}
