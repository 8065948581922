import { ChartConfigChartPaneElement, RequireByKey } from '../../types/shared'
import { RsiCalculation } from '../indicator-calculation/rsi'
import { Attrs, RSIConfig } from './configs/rsi'
import Indicator from './indicator'

class RSI extends Indicator<Attrs> {
  static config = RSIConfig

  rsiCalculation: RsiCalculation | null = null
  declare period: number

  static getNumOfBarsBuffer(attrs: RequireByKey<ChartConfigChartPaneElement, 'period'>) {
    // Because of this smoothing, RSI values may differ based on the total calculation period. 250 periods will allow for more smoothing than 30 periods, which will slightly affect RSI values.
    return super.getNumOfBarsBuffer(attrs) + 250
  }

  set(values: Partial<Attrs>) {
    super.set(values)
    this.parsePeriodInt(values)
  }

  compute() {
    if (!this.isComputeNecessary() && this.rsiCalculation !== null) return

    this.rsiCalculation = new RsiCalculation({
      quote: this.data,
      options: { period: this.period },
    })

    this.rsiCalculation.calculate()

    this.lastValue = this.rsiCalculation.calculatedValues.rsi.last() ?? null
    const { min, max } = this.getDomainDefaults(this.type)
    this.min = min
    this.max = max
  }

  getValueLabelsAtIndex(index: number) {
    return this.getOversoldOverboughtValueLabelsAtIndex(index, this.rsiCalculation?.calculatedValues.rsi ?? [])
  }

  renderIndicator(context: CanvasRenderingContext2D) {
    this.renderOversoldOverbought(context, this.rsiCalculation?.calculatedValues.rsi ?? [], this.period, 30, 50, 70)
  }

  getModalConfig() {
    const options = {
      period: {
        type: 'number',
        label: 'Period',
        name: 'period',
        value: this.period ?? 14,
        required: true,
        min: 1,
        max: 999999,
      },
    }

    return {
      title: RSIConfig.label,
      inputs: RSIConfig.inputsOrder.map((item) => options[item]),
      inputsErrorMessages: {
        period: `${options.period.label} must be a whole number between ${options.period.min} and ${options.period.max}`,
      },
    }
  }

  getIsValid(key: string): boolean {
    switch (key) {
      case 'period':
        return this.getIsNumberInputValid({ key })
      default:
        return false
    }
  }
}

export default RSI
