import { ObjectHash } from '../../types/shared'
import IndicatorBaseConfig from '../indicators/configs/indicatorBaseConfig'
import type Quote from '../models/quote'
import OverlayBaseConfig from '../overlays/configs/overlayBaseConfig'

type OptionValuesType = ObjectHash<unknown>
type CalculatedValuesType = ObjectHash<number[]>
interface MainProps<T> {
  quote: Quote
  options: T
}

export class MainCalculation<
  TOptionValues extends OptionValuesType | undefined = undefined,
  TCalculatedValues extends CalculatedValuesType = CalculatedValuesType,
> {
  declare static config: OverlayBaseConfig | IndicatorBaseConfig

  quote: NonNullable<MainProps<TOptionValues>['quote']>
  options: MainProps<TOptionValues>['options']

  declare protected _calculatedValues: TCalculatedValues

  constructor({ quote, options }: MainProps<TOptionValues>) {
    this.quote = quote
    this.options = options
  }

  get calculatedValues() {
    return this._calculatedValues ?? this.getDefaultCalculatedValues()
  }

  get config() {
    return (this.constructor as typeof MainCalculation).config
  }

  getDefaultCalculatedValues(): TCalculatedValues {
    const defaultCalculatedValues = this.config.getDefaultCalculatedValues() as TCalculatedValues
    if (Object.keys(defaultCalculatedValues).length === 0) {
      throw Error('Add calculatedValues to indicator config')
    }
    return defaultCalculatedValues
  }

  calculate() {
    throw Error('Implement calculate')
  }

  getAllCalculatedValues(lastNItems: number) {
    const calculatedValues = {} as TCalculatedValues
    Object.keys(this.calculatedValues).forEach((key) => {
      calculatedValues[key as keyof TCalculatedValues] = this.getCalculatedValues(key, lastNItems)
    })
    return calculatedValues
  }

  getCalculatedValues(key: keyof TCalculatedValues, lastNItems: number) {
    const result = (this.calculatedValues[key] as number[]).slice(-lastNItems)
    return (result.length > 0 ? result : [...Array(lastNItems)]) as TCalculatedValues[keyof TCalculatedValues]
  }

  /**
   * This function is used to construct sql script for db table creation
   * and also to build precalculated data object used on node server.
   *
   * Precalculated data object uses database column names as property keys to simplify data mapping on BE
   */
  getDbColumnName() {
    const colName = this.config.abbreviation
    const optionKeys = this.config.optionsOrder

    const optionsStrings = optionKeys.map((key) => this.options![key])
    return [colName, ...optionsStrings].join('_')
  }
}
