import debounce from 'lodash.debounce'
import React from 'react'

import Chart from '../../models/chart'
import Quote from '../../models/quote'

const MAX_QUOTE_CACHE_TIME_IN_MS = 300000 // 5 min

export const withQuoteCacheManagement = (Component: any) => (props: any) => {
  React.useEffect(() => {
    const cleanupQuoteCache = () => {
      const allQuotes = Quote.all()
      const allCharts = Chart.all()
      const allChartQuotes = allCharts.flatMap((chart) => chart.getAllQuotes())

      allQuotes.forEach((quote) => {
        if (
          !allChartQuotes.some((usedQuote) => usedQuote.eql(quote)) &&
          !quote.getIsFetching() &&
          quote.fetchedAt > -1 &&
          quote.fetchedAt < Date.now() - MAX_QUOTE_CACHE_TIME_IN_MS
        ) {
          quote.destroy()
        }
      })
    }

    const checkQuoteCacheDebounce = debounce(cleanupQuoteCache, 500)

    Quote.bind('change', checkQuoteCacheDebounce)
    return () => {
      checkQuoteCacheDebounce.cancel()
      Quote.unbind('change', checkQuoteCacheDebounce)
    }
  }, [])

  return <Component {...props} />
}
