import { Instrument } from '../../types/shared'
import Line from '../canvas/line'
import Text from '../canvas/text'
import {
  CanvasElementType,
  ChartElementType,
  ChartEventType,
  IndicatorType,
  IntradayTimeframeInterval,
  MONTHS,
  OFFSET,
  OverlayType,
  SpecificChartFunctionality,
  TIMEFRAME,
} from '../constants/common'
import { getIsSmallIndexChart, getTranslate } from '../controllers/renderUtils'
import { ISettings } from '../models/chart_settings/interfaces'
import Pane from '../models/pane'
import Quote from '../models/quote'
import utils, { dateFromDateString } from '../utils'
import { getBarWidthWithMargin, getHalfBarWidth } from './chart'
import { getVisibleBarToRenderIndex } from './draw_in_visible_area'

interface RenderXAxisProps {
  context: CanvasRenderingContext2D
  textRenderer?: Text
  paneModel: Pane
  quote: Quote
  type: CanvasElementType | OverlayType | ChartElementType | IndicatorType | ChartEventType
}

interface RenderTimeframeXAxisProps extends Omit<RenderXAxisProps, 'type'> {
  startBarIndex: number
  endBarIndex: number
  fx: (x: number) => number
}

interface XAxisLabel {
  x: number
  text: string
  textWidth: number
  hasPriority?: boolean
  allowedOverflow?: number
}

function getXLine({ paneModel, height }: { paneModel: Pane; height: number }): Line {
  const chartLayoutSettings = paneModel.getChartLayoutSettings()
  return new Line(
    {
      y1: 0,
      y2: height,
      strokeStyle: chartLayoutSettings.ChartSettings.general.Colors.grid,
      dashLength: 3,
    },
    paneModel
  )
}

function isXPointVisible({ buffer = 0, x, width }: { x: number; buffer?: number; width: number }) {
  return x >= 0 - buffer && x <= width + buffer
}

function getIsOverlapping({
  x,
  textWidth,
  renderedLabelRanges,
}: {
  x: number
  textWidth: number
  renderedLabelRanges: Array<{ from: number; to: number }>
}) {
  return renderedLabelRanges.some(({ from, to }) => {
    const minX = x - textWidth / 2
    const maxX = x + textWidth / 2
    return (from < maxX && maxX < to) || (from < minX && minX < to)
  })
}

function renderXAxisLabels({
  context,
  width,
  labels,
  textRenderer,
  allowedOverflow = 0,
}: {
  context: CanvasRenderingContext2D
  width: number
  labels: Omit<XAxisLabel, 'textWidth'>[]
  textRenderer: Text
  allowedOverflow?: number
}) {
  const sortedLabels: XAxisLabel[] = []
  const renderedLabelRanges: Array<{ from: number; to: number }> = []
  labels.reverse().forEach((label) => {
    textRenderer.set({ text: label.text })
    const textWidth = Math.floor(textRenderer.measure(context))
    const newLabel = { ...label, textWidth }
    if (label.hasPriority) {
      sortedLabels.unshift(newLabel)
    } else {
      sortedLabels.push(newLabel)
    }
  })

  sortedLabels.forEach(({ x, text, textWidth }) => {
    if (!isXPointVisible({ x, width, buffer: allowedOverflow })) {
      return
    }
    const halfOfTextWidth = textWidth / 2
    const overflowLeft = x - halfOfTextWidth + allowedOverflow
    const overflowRight = width - (x + halfOfTextWidth) + allowedOverflow
    let labelX = x
    if (overflowLeft < 0) {
      labelX = Math.round(x - overflowLeft)
    } else if (overflowRight < 0) {
      labelX = Math.round(x + overflowRight)
    }
    if (getIsOverlapping({ x: labelX, textWidth, renderedLabelRanges })) {
      return
    }
    renderedLabelRanges.push({
      from: labelX - halfOfTextWidth - OFFSET.M,
      to: labelX + halfOfTextWidth + OFFSET.M,
    })
    textRenderer.set({ text, x: labelX })
    textRenderer.render(context)
  })
}

function getSize(paneModel: Pane) {
  const isIndicator = paneModel.getIsIndicatorPane()
  const { IndicatorSettings, ChartSettings, MarketSentimentSettings } = paneModel.getChartLayoutSettings()
  let settings: ISettings['IndicatorSettings' | 'ChartSettings' | 'MarketSentimentSettings'] = ChartSettings

  if (isIndicator) {
    settings = IndicatorSettings
  } else if (paneModel.mainElement()?.getIsChartType(ChartElementType.MarketSentiment)) {
    settings = MarketSentimentSettings
  }

  const paddingX = settings.left.width + settings.right.width
  const paddingY = settings.top.height + settings.bottom.height

  const chartWidth = paneModel.chart().width
  const paneHeight = paneModel.height
  return { width: chartWidth ? chartWidth - paddingX : 0, height: paneHeight ? paneHeight - paddingY : 0 }
}

function renderDailyXAxis({
  context,
  quote,
  paneModel,
  textRenderer,
  startBarIndex,
  endBarIndex,
  fx,
}: RenderTimeframeXAxisProps) {
  const { width, height } = getSize(paneModel)

  const chartModel = paneModel.chart()
  let lastMonth = ''

  const labels = []
  const xLine = getXLine({ paneModel, height })
  for (let i = startBarIndex; i <= endBarIndex; i++) {
    const date = utils.dateFromUnixTimestamp(quote.date[i])
    const month = date.getMonth() + '-' + date.getFullYear()
    if (month !== lastMonth) {
      lastMonth = month
      const x = Math.round(fx(i) + chartModel.leftOffset)
      if (!isXPointVisible({ x, width })) {
        continue
      }
      xLine.set({ x1: x, x2: x }).render(context)
      labels.push({
        hasPriority: date.getMonth() === 0,
        text: date.getMonth() === 0 ? date.getFullYear().toString() : MONTHS[date.getMonth()],
        x,
      })
    }
  }
  if (textRenderer) {
    renderXAxisLabels({ context, width, labels, textRenderer })
  }
}

function render15MinXAxis({
  context,
  quote,
  paneModel,
  textRenderer,
  startBarIndex,
  endBarIndex,
  fx,
}: RenderTimeframeXAxisProps) {
  const { width, height } = getSize(paneModel)
  const chartModel = paneModel.chart()
  const chartLayout = chartModel.chart_layout()
  let lastDay = ''
  let x = 0

  const labels = []
  const xLine = getXLine({ paneModel, height })
  for (let i = startBarIndex; i <= endBarIndex; i++) {
    const date = utils.dateFromUnixTimestamp(quote.date[i])
    const day = date.getDate() + '-' + date.getMonth()
    if (day !== lastDay) {
      lastDay = day
      if (quote.instrument === Instrument.Stock) {
        const barAtMinute = date.getMinutes() + date.getHours() * 60
        const indexOffset = (barAtMinute - quote.marketStartMinutes) / quote.interval
        x = Math.round(paneModel.scale.x(quote.barIndex[i] - indexOffset) + chartModel.leftOffset)
      } else {
        x = Math.round(fx(i) + chartModel.leftOffset)
      }
      if (!isXPointVisible({ x, width })) {
        continue
      }
      xLine.set({ x1: x, x2: x }).render(context)
      labels.push({
        text: MONTHS[date.getMonth()] + ' ' + date.getDate().toString().padLeft('00'),
        x,
      })
    }
  }

  // Offscreen only - if lastDate !== lastBarDate add tick
  if (chartLayout.specificChartFunctionality === SpecificChartFunctionality.offScreen) {
    const lastDate = `${MONTHS[(~~(quote.lastDate / 100) % 100) - 1]} ${(quote.lastDate % 100)
      .toString()
      .padLeft('00')}` // YYYYMMDD / 20211118 => Nov 18

    if (labels.length > 0 && labels[labels.length - 1].text !== lastDate) {
      const barsInDay = quote.getBarsInDay()
      const barWidth = getBarWidthWithMargin({
        zoomFactor: chartModel.zoomFactor,
        chartLayout,
      })
      const dayWidth = barsInDay * barWidth
      x += dayWidth
      labels.push({
        text: lastDate,
        x,
      })
      xLine.set({ x1: x, x2: x }).render(context)
    }
  }

  if (textRenderer) {
    renderXAxisLabels({ context, width, labels, textRenderer })
  }
}

const SMALL_INDEX_CHART_LABEL_BUFFER = 15

function renderIntradayXAxis({ context, quote, paneModel, textRenderer }: Omit<RenderXAxisProps, 'type' | 'fx'>) {
  const { width, height } = getSize(paneModel)
  const chartModel = paneModel.chart()
  const chartLayout = chartModel.chart_layout()
  const interval = IntradayTimeframeInterval[quote.timeframe as keyof typeof IntradayTimeframeInterval]
  const marketStartHour = ~~(quote.marketStartMinutes / 60)
  let marketEndHour = 15 + Math.ceil(quote.aftermarketLengthMinutes / 60)
  const labels = []
  const isSmallIndexChart = getIsSmallIndexChart(chartLayout.specificChartFunctionality)
  if (isSmallIndexChart) {
    marketEndHour = 16
  }

  const xLine = getXLine({ paneModel, height })
  const barWidth = getBarWidthWithMargin({
    zoomFactor: chartModel.zoomFactor,
    chartLayout,
  })

  const dayToOffset = quote.getDayToOffset()
  const allowedOverflow = isSmallIndexChart ? SMALL_INDEX_CHART_LABEL_BUFFER : 0
  for (const day in dayToOffset) {
    const dayIndex = dayToOffset[day]
    const date = dateFromDateString(day)

    for (let i = marketStartHour; i <= marketEndHour; i++) {
      let x =
        ((i * 60 - quote.marketStartMinutes) / interval) * barWidth +
        dayIndex * (quote.drawMinutesPerDay / interval) * barWidth +
        chartModel.leftOffset
      if (!isXPointVisible({ x, width, buffer: allowedOverflow })) {
        continue
      }
      let roundedX = Math.round(x)

      if (i === marketStartHour) {
        if (isSmallIndexChart) {
          roundedX = Math.round(x - 3 + (30 / interval) * barWidth)
          // ~9:30AM
          xLine
            .set({
              x1: roundedX,
              x2: roundedX,
            })
            .render(context)
          continue
        }
        if (quote.premarketLengthMinutes === 0) {
          // If there is no premarket, day starts at 9:30 AM
          x += (30 / interval) * barWidth
        }
        roundedX = Math.round(x)
        xLine.set({ x1: roundedX, x2: roundedX }).render(context)
        labels.push({
          hasPriority: true,
          text: MONTHS[date.getMonth()] + ' ' + date.getDate().toString().padLeft('00'),
          x: roundedX,
        })
      } else {
        xLine.set({ x1: roundedX, x2: roundedX }).render(context)
        labels.push({
          text: ((i + 11) % 12) + 1 + (i < 12 ? 'AM' : 'PM'),
          x: roundedX,
        })
      }
    }
  }

  if (textRenderer) {
    renderXAxisLabels({ context, width, labels, textRenderer, allowedOverflow })
  }
}

function renderIntradayXAxisFutures({
  context,
  quote,
  paneModel,
  textRenderer,
  startBarIndex,
  endBarIndex,
  fx,
}: RenderTimeframeXAxisProps) {
  const { width, height } = getSize(paneModel)
  const chartModel = paneModel.chart()
  let lastDay = ''
  let lastHours = NaN

  const labels = []
  const xLine = getXLine({ paneModel, height })
  for (let i = startBarIndex; i <= endBarIndex; i++) {
    const date = utils.dateFromUnixTimestamp(quote.date[i])
    const day = date.getDate() + '-' + date.getMonth()
    const hours = date.getHours()
    const x = Math.round(fx(i) + chartModel.leftOffset)

    if (day !== lastDay) {
      lastDay = day
      lastHours = hours
      if (!isXPointVisible({ x, width })) {
        continue
      }
      xLine.set({ x1: x, x2: x }).render(context)
      labels.push({
        x,
        text: MONTHS[date.getMonth()] + ' ' + date.getDate().toString().padLeft('00'),
      })
    } else if (hours % 2 === 0 && hours !== lastHours) {
      lastHours = hours
      if (!isXPointVisible({ x, width })) {
        continue
      }
      xLine.set({ x1: x, x2: x }).render(context)
      labels.push({ x, text: ((hours + 11) % 12) + 1 + (hours < 12 ? 'AM' : 'PM') })
    }
  }

  if (textRenderer) {
    renderXAxisLabels({ context, width, labels, textRenderer })
  }
}

function renderWeeklyXAxis({
  context,
  quote,
  paneModel,
  textRenderer,
  startBarIndex,
  endBarIndex,
  fx,
}: RenderTimeframeXAxisProps) {
  const { width, height } = getSize(paneModel)
  const chartModel = paneModel.chart()
  let lastMonth = ''

  const labels = []
  const xLine = getXLine({ paneModel, height })
  const { Colors } = paneModel.getChartLayoutSettings().ChartSettings.general
  for (let i = startBarIndex; i <= endBarIndex; i++) {
    const date = utils.dateFromUnixTimestamp(quote.date[i])
    const month = date.getMonth() + '-' + date.getFullYear()
    if (month !== lastMonth) {
      lastMonth = month
      const x = Math.round(fx(i) + chartModel.leftOffset)
      if (!isXPointVisible({ x, width })) {
        continue
      }
      if (date.getMonth() % 3 === 0) {
        xLine.set({ x1: x, x2: x }).render(context)
      } else {
        new Line(
          {
            x1: x,
            x2: x,
            y1: 0,
            y2: height,
            strokeStyle: Colors.gridSecondary,
            dashLength: 3,
          },
          paneModel
        ).render(context)
      }
      const isYearLabel = date.getMonth() === 0
      labels.push({
        text: isYearLabel ? date.getFullYear().toString().substring(2) : MONTHS[date.getMonth()][0],
        hasPriority: isYearLabel,
        x,
      })
    }
  }

  if (textRenderer) {
    renderXAxisLabels({ context, width, labels, textRenderer })
  }
}

function renderMonthlyXAxis({
  context,
  quote,
  paneModel,
  textRenderer,
  startBarIndex,
  endBarIndex,
  fx,
}: RenderTimeframeXAxisProps) {
  const { width, height } = getSize(paneModel)
  const chartModel = paneModel.chart()
  let lastYear = null

  const labels = []
  const xLine = getXLine({ paneModel, height })
  for (let i = startBarIndex; i <= endBarIndex; i++) {
    const date = utils.dateFromUnixTimestamp(quote.date[i])
    const year = date.getFullYear()
    const isJanuary = date.getMonth() === 0
    if (year !== lastYear && (labels.length !== 0 || isJanuary)) {
      lastYear = year
      const x = Math.round(fx(i) + chartModel.leftOffset)
      if (!isXPointVisible({ x, width })) {
        continue
      }
      xLine.set({ x1: x, x2: x }).render(context)
      labels.push({
        text: date.getFullYear().toString(),
        x,
      })
    }
  }

  if (textRenderer) {
    renderXAxisLabels({ context, width, labels, textRenderer })
  }
}

export function renderXAxis({ context, quote, paneModel, textRenderer, type: elementType }: RenderXAxisProps) {
  const fx = (x: number) => paneModel.scale.x(quote.barIndex[x])
  const { width } = getSize(paneModel)
  const chartModel = paneModel.chart()
  const chartLayout = chartModel.chart_layout()
  const leftOffset = chartModel.leftOffset

  // Ticks are placed at the start of each timeframe period (hour/day/month/year).
  // The "-1" in the code ensures we start searching from one bar outside the visible area,
  // avoiding a tick on the first visible index unless it marks the start of a new timeframe period.
  // For example, if the start of December is on December 3rd and the first visible bar is on December 14th,
  // we won't display a tick.
  const startBarIndex = Math.max(
    getVisibleBarToRenderIndex({
      quote,
      paneModel,
      leftOffset,
    }).dataIndex - 1,
    0
  )

  const endBarIndex = Math.min(
    getVisibleBarToRenderIndex({
      quote,
      paneModel,
      leftOffset,
      chartWidth: width,
    }).dataIndex + 1,
    quote.close.length - 1
  )

  const renderTimeframeXAxisProps = { context, quote, paneModel, textRenderer, startBarIndex, endBarIndex, fx }

  switch (quote.timeframe) {
    case TIMEFRAME.i1:
    case TIMEFRAME.i2:
    case TIMEFRAME.i3:
    case TIMEFRAME.i5:
      if (quote.instrument === Instrument.Stock) {
        if (
          elementType === ChartElementType.LineChart &&
          chartLayout.specificChartFunctionality === SpecificChartFunctionality.offScreen &&
          quote.timeframe === 'i5'
        ) {
          render15MinXAxis(renderTimeframeXAxisProps)
        } else {
          renderIntradayXAxis({ context, quote, paneModel, textRenderer })
        }
      } else {
        renderIntradayXAxisFutures(renderTimeframeXAxisProps)
      }
      break
    case TIMEFRAME.i10:
      if (getIsSmallIndexChart(chartLayout.specificChartFunctionality)) {
        renderIntradayXAxis({ context, quote, paneModel, textRenderer })
      } else {
        render15MinXAxis(renderTimeframeXAxisProps)
      }
      break
    case TIMEFRAME.i15:
    case TIMEFRAME.i30:
    case TIMEFRAME.h:
    case TIMEFRAME.h2:
    case TIMEFRAME.h4:
      render15MinXAxis(renderTimeframeXAxisProps)
      break
    case TIMEFRAME.d:
      renderDailyXAxis(renderTimeframeXAxisProps)
      break
    case TIMEFRAME.w:
      renderWeeklyXAxis(renderTimeframeXAxisProps)
      break
    case TIMEFRAME.m:
      renderMonthlyXAxis(renderTimeframeXAxisProps)
      break
    default:
      break
  }
}

interface RenderFadeExtendedHoursProps {
  context: CanvasRenderingContext2D
  paneModel: Pane
  quote: Quote
}
// only for stocks on i1/i3/i5 and one day view
export function renderFadeExtendedHours({ context, paneModel, quote }: RenderFadeExtendedHoursProps) {
  const isIndicator = paneModel.getIsIndicatorPane()
  const { height } = getSize(paneModel)
  const { ChartSettings, IndicatorSettings } = paneModel.getChartLayoutSettings()
  const { Colors } = ChartSettings.general
  const chartModel = paneModel.chart()
  const period = quote.timeframe
  if (quote.instrument !== Instrument.Stock) {
    return
  }
  if (![TIMEFRAME.i1, TIMEFRAME.i2, TIMEFRAME.i3, TIMEFRAME.i5].includes(period)) {
    return
  }

  const interval = parseInt(period.substring(1), 10)
  const dayToOffset = quote.getDayToOffset()
  const translate = getTranslate({
    context,
    xOffset: chartModel.leftOffset + (isIndicator ? IndicatorSettings : ChartSettings).left.width,
    yOffset: (isIndicator ? IndicatorSettings : ChartSettings).top.height,
  })
  translate.do()
  const halfBarWidth = getHalfBarWidth(chartModel)
  for (const day in dayToOffset) {
    // todo aftermarket
    const dayIndex = dayToOffset[day]
    let n0 = (quote.drawMinutesPerDay / interval) * dayIndex
    let n1 = quote.premarketLengthMinutes / interval + n0 // bars in 90mins from 8am to 9:30am
    let x0 = paneModel.scale.x(n0) - halfBarWidth
    let width = paneModel.scale.x(n1) - paneModel.scale.x(n0)
    context.set('fillStyle', Colors.premarketFade)
    context.fillRect(x0, 0, width, height + 1)

    if (quote.aftermarketLengthMinutes > 0) {
      n0 =
        (quote.drawMinutesPerDay / interval) * dayIndex +
        (quote.drawMinutesPerDay - quote.aftermarketLengthMinutes) / interval
      n1 = quote.aftermarketLengthMinutes / interval + n0 // bars in 90mins from 4pm to 5:30pm
      x0 = paneModel.scale.x(n0) - halfBarWidth
      width = paneModel.scale.x(n1) - paneModel.scale.x(n0)

      context.set('fillStyle', Colors.aftermarketFade)
      context.fillRect(x0, 0, width, height + 1)
    }
  }
  translate.undo()
}
