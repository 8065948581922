import { Spinner } from '@finviz/website'
import classnames from 'classnames'
import * as React from 'react'

import { ChartConfigChartPane, CustomSpineEvents } from '../../../types/shared'
import { IndicatorType } from '../../constants/common'
import { getChartBarsBuffer } from '../../helpers/getGetChartBarsBuffer'
import PricePerformance from '../../indicators/perf'
import { useModelState } from '../../model-hooks/use-model-state'
import Chart from '../../models/chart'
import { CHART_BARS_BUFFER_IN_PX } from '../../models/constants'
import MouseModel from '../../models/mouse'
import { QuoteFetchType } from '../../models/quote/constants'
import { getBarWidthWithMargin } from '../../utils/chart'
import { useIsMounted } from '../../utils/use-is-mounted'
import { getLeftOffset, getMinMaxLeftOffset } from '../renderUtils'

interface LoadLatestDataButtonProps {
  chartModel: Chart
}

const BIND_EVENTS = `change ${CustomSpineEvents.OverlaysChange} ${CustomSpineEvents.IndicatorsChange}`

export function MainChartDataLoader({ chartModel: chartModelUnwatched }: LoadLatestDataButtonProps) {
  const getIsMounted = useIsMounted()
  const chartModel = useModelState(chartModelUnwatched, { watchProperties: ['quote'] })
  const quoteModel = useModelState(chartModel.quote(), { watchProperties: ['fetchingState', 'hasReachedEnd'] })
  const wrapperRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    if (chartModelUnwatched.getAllQuotes().every((quote) => quote.hasReachedEnd)) {
      return
    }

    const handleChartChange = (chartModel: Chart = chartModelUnwatched) => {
      if (!getIsMounted()) {
        return
      }

      const barsBuffer = getChartBarsBuffer({
        panes: chartModel.getAllPanes().map((pane) => pane.toConfig() as ChartConfigChartPane),
        timeframe: chartModel.timeframe,
      })

      if (barsBuffer !== chartModel.barsBuffer) {
        chartModel.updateAttribute('barsBuffer', barsBuffer)
        return
      }

      const chartLayout = chartModel.chart_layout()
      const barWidth = getBarWidthWithMargin({
        chartLayout,
        zoomFactor: chartModel.zoomFactor,
      })

      const mainQuote = chartModel.quote()
      wrapperRef.current?.style.setProperty(
        '--leftOffset',
        `${Math.min(mainQuote.barIndex[0] * barWidth + chartModel.leftOffset, CHART_BARS_BUFFER_IN_PX)}px`
      )

      const quoteBarsBufferAndCount = chartModel.getQuoteBarsBufferAndCount()
      const isLoadAll =
        quoteBarsBufferAndCount.dateFrom === undefined && quoteBarsBufferAndCount.barsCount === undefined
      const bufferInPx = (quoteBarsBufferAndCount.leftBuffer ?? 0) * barWidth

      const perfIndicatorInstances = chartModel
        .getAllElements()
        .filter(({ instance }) => instance.type === IndicatorType.Perf)
        .map((element) => element.instance as unknown as PricePerformance)
      const perfIndicatorTickers = perfIndicatorInstances.flatMap((instance) =>
        Object.values(instance.quotes).map(({ ticker }) => ticker)
      )

      chartModel.getAllQuotes().forEach((quote) => {
        const isTickerInChart =
          chartModel.ticker === quote.getRawTicker() || perfIndicatorTickers.includes(quote.ticker)
        const isChartTimeframe = chartModel.timeframe === quote.timeframe
        if (quote.hasReachedEnd || quote.hasError || !isTickerInChart || !isChartTimeframe) {
          return
        }

        const isMainQuote = quote.eql(mainQuote)
        const firstBarIndex = isMainQuote ? mainQuote.barIndex[0] : mainQuote.getPositionXFromTimestamp(quote.date[0])
        const scrolledInBuffer = bufferInPx + chartModel.leftOffset + firstBarIndex * barWidth
        if (
          (isLoadAll || scrolledInBuffer >= 0) &&
          !quote.getIsFetching([QuoteFetchType.Refetch, QuoteFetchType.DataChangeRefetch, QuoteFetchType.Older])
        ) {
          if (isMainQuote) {
            window.gtag?.('event', 'load-previous-data', { event_label: chartLayout.specificChartFunctionality })
          }
          const oldMaxBarIndex = quote.barIndex[quote.open.length - 1]

          quote
            .fetchOlderData(
              isLoadAll ? undefined : { barsCount: Math.max(Math.ceil(scrolledInBuffer / barWidth), 100) }
            )
            ?.then(() => {
              if (!getIsMounted()) {
                return
              }
              const chartModelsUsingTheQuote = chartModel
                .chart_layout()
                .getAllCharts()
                // Chart quote can be null if chart has just mounted and quote object is not available yet (loading), or chart is unmounting
                .filter((chart) => chart.quote()?.eql(quote))

              chartModelsUsingTheQuote.forEach((chartModel) => {
                const newQuote = chartModel.quote()
                const newBarWidth = getBarWidthWithMargin({
                  chartLayout: chartModel.chart_layout(),
                  zoomFactor: chartModel.zoomFactor,
                })
                const oldMaxLeftOffset = oldMaxBarIndex * newBarWidth
                const lastBarIndex = newQuote.barIndex[newQuote.open.length - 1]
                const newMaxLeftOffset = lastBarIndex * newBarWidth

                chartModel.updateAttributes({
                  leftOffset: chartModel.isScrolled
                    ? getMinMaxLeftOffset({
                        newLeftOffset: chartModel.leftOffset - (newMaxLeftOffset - oldMaxLeftOffset),
                        chartModel,
                        barIndex: lastBarIndex,
                      })
                    : getLeftOffset({
                        quote,
                        chartModel,
                      }),
                })

                chartModel.getAllPanes().forEach((pane) => {
                  if (MouseModel.position && MouseModel.pane()?.eql(pane)) {
                    MouseModel.updateAttributes({
                      position: {
                        ...MouseModel.position,
                        x: MouseModel.position.x + (lastBarIndex - oldMaxBarIndex),
                      },
                    })
                  }

                  pane.getAllElements().forEach((element) => {
                    if (element.isDrawing()) {
                      element.instance?.updateScales()
                    }
                  })
                })
              })

              perfIndicatorInstances.forEach((instance) => {
                void instance.compute(true)
              })

              setTimeout(() => {
                handleChartChange(chartModelUnwatched)
              })
            })
        }
      })
    }

    handleChartChange(chartModelUnwatched)

    chartModelUnwatched.bind(BIND_EVENTS, handleChartChange)

    return () => {
      chartModelUnwatched.unbind(BIND_EVENTS, handleChartChange)
    }
  }, [chartModelUnwatched, quoteModel.hasReachedEnd, getIsMounted])

  if (quoteModel.hasReachedEnd || quoteModel.close.length === 0) {
    return null
  }

  return (
    <div
      ref={wrapperRef}
      className={classnames(
        'pointer-events-none absolute left-0 top-0 z-50 flex h-full w-[100px] items-center justify-start overflow-hidden'
      )}
      style={{ left: chartModel.chart_layout().settings.ChartSettings.left.width }}
    >
      <div style={{ transform: `translateX(calc(-58px + var(--leftOffset, 0px))` }}>
        <Spinner width={16} />
      </div>
    </div>
  )
}
