import { RendererChartBarProps } from '../../types/shared'
import { NumOfBarsChartPaneElementArgs } from '../canvas/element'
import { ChartElementType } from '../constants/common'
import CandleStick from './candle_stick'

class HollowCandleStick extends CandleStick {
  static type = ChartElementType.HollowCandleStick
  static label = 'Hollow Candle'
  static iconName = 'candleBarHollow'

  static getNumOfBarsBuffer(obj: NumOfBarsChartPaneElementArgs) {
    return Math.max(1, super.getNumOfBarsBuffer(obj))
  }

  getRenderChartBarProps({ index, chartSettingsColors }: RendererChartBarProps) {
    const open = this.data.open[index]
    const close = this.data.close[index]
    const isHollow = close > open
    const generalTrendColor =
      close > this.data.close[index - 1] ? chartSettingsColors.ohlcTrendUp : chartSettingsColors.ohlcTrendDown
    const trendColor = index === 0 ? chartSettingsColors.zeroChange : generalTrendColor
    return {
      open,
      close,
      high: this.data.high[index],
      low: this.data.low[index],
      // hollow candles have transparend bodies that's why rgba is here
      barFillColor: isHollow ? 'rgba(0, 0, 0, 0)' : trendColor,
      borderColor: trendColor,
      wickColor: trendColor,
    }
  }

  toString() {
    return `Hollow Candle stick ${this.data.ticker}`
  }
}

export default HollowCandleStick
