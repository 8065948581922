import { RendererChartBarProps } from '../../types/shared'
import { NumOfBarsChartPaneElementArgs } from '../canvas/element'
import { ChartElementType, ScaleType } from '../constants/common'
import Quote from '../models/quote'
import { getTickerChangeFromCloseValues } from '../utils/chart'
import CandleStick from './candle_stick'

type OhlcKeys = 'high' | 'low' | 'open' | 'close'

class HeikinAshi extends CandleStick {
  static type = ChartElementType.HeikinAshi
  static label = 'Heikin Ashi'
  static iconName = 'candleBarHeikinAshi'

  static getNumOfBarsBuffer(obj: NumOfBarsChartPaneElementArgs) {
    return Math.max(1, super.getNumOfBarsBuffer(obj))
  }

  getRenderChartBarProps = ({ index, chartSettingsColors, hasFillAndBorder }: RendererChartBarProps) => {
    const haData = this.data.getHeikinAshiData()

    const open = haData.open[index]
    const close = haData.close[index]
    const trendColor = close < open ? chartSettingsColors.trendDown : chartSettingsColors.trendUp
    const wickColor = close < open ? chartSettingsColors.wickDown : chartSettingsColors.wickUp
    return {
      open,
      close,
      high: Math.max(this.data.high[index], open, close),
      low: Math.min(open, close, this.data.low[index]),
      barFillColor: trendColor,
      borderColor: close < open ? chartSettingsColors.borderDown : chartSettingsColors.borderUp,
      wickColor: hasFillAndBorder ? wickColor : trendColor,
    }
  }

  getLastValues = () => {
    const Colors = this.getCandleLikeChartsRenderingColors()
    const {
      open: lastOpen,
      close: lastClose,
      high: lastHigh,
      low: lastLow,
    } = this.getRenderChartBarProps({
      index: this.data.date.length - 1,
      chartSettingsColors: Colors,
    })

    return { lastOpen, lastClose, lastHigh, lastLow }
  }

  getRoundedLastData() {
    const lastOhlc = this.getLastValues()
    return {
      open: this.roundOhlc(lastOhlc.lastOpen),
      high: this.roundOhlc(lastOhlc.lastHigh),
      low: this.roundOhlc(lastOhlc.lastLow),
      close: this.roundOhlc(lastOhlc.lastClose),
    }
  }

  renderYAxis = (context: CanvasRenderingContext2D) => {
    const { lastClose } = this.getLastValues()
    super.renderYAxis(context, { lastClose }, false)

    const { firstVisibleClose } = this.getMinMax()
    if (this.model.scale === ScaleType.Percentage && !firstVisibleClose) return

    const originalLatestClose = this.data.close[this.data.close.length - 1]
    const heikinLatestCloseLabel = this.getYAxisLastCloseLabel({ lastClose, firstVisibleClose })
    const originalLatestCloseLabel = this.getYAxisLastCloseLabel({ lastClose: originalLatestClose, firstVisibleClose })

    this.renderYAxisLabel({ context, yCoordinate: Math.round(this.fy(lastClose)), label: heikinLatestCloseLabel })

    if (heikinLatestCloseLabel === originalLatestCloseLabel) return

    this.renderYAxisLabel({
      context,
      yCoordinate: this.getYAxisSecondaryLabelYCoordinate({
        mainLabelValue: lastClose,
        secondaryLabelValue: originalLatestClose,
      }),
      label: originalLatestCloseLabel,
      background: this.getSecondaryLabelBackgroundColor(),
    })
  }

  getDataByCrossIndex(crossIndex: number, key: keyof Quote, shouldReturnRoundedString = true) {
    const Colors = this.getCandleLikeChartsRenderingColors()
    const barIndex = this.data.getDataIndexByBarIndex(crossIndex)
    if (barIndex > -1) {
      let value = null
      if (['high', 'low', 'open', 'close'].includes(key)) {
        const data = this.getRenderChartBarProps({ index: barIndex, chartSettingsColors: Colors })
        value = data[key as OhlcKeys]
      }
      value = value === null ? this.data.getDataByBarIndex(key, crossIndex) : value
      if (value !== null) {
        return shouldReturnRoundedString ? this.roundOhlc(value) : `${value}`
      }
    }
  }

  getCrossIndexChange(crossIndex: number) {
    const barIndex = this.data.getDataIndexByBarIndex(crossIndex)
    const haData = this.data.getHeikinAshiData()
    const crossClose = haData.close[barIndex] ?? null
    const previousClose = haData.close[barIndex - 1] ?? null
    if (crossClose !== null && previousClose !== null) {
      return getTickerChangeFromCloseValues({ data: this.data, anchorClose: crossClose, prevClose: previousClose })
    }

    return null
  }

  toString() {
    return `HA Candle stick ${this.data.ticker}`
  }
}

export default HeikinAshi
