import { ChartConfigChartPane } from '../../types/shared'
import { TIMEFRAME } from '../constants/common'
import { getCanvasElementByType } from './get-canvas-element-by-type'

export const getChartBarsBuffer = ({ panes, timeframe }: { panes: ChartConfigChartPane[]; timeframe: TIMEFRAME }) => {
  const maxNumOfBarsBuffer =
    panes
      .flatMap(({ elements }) => elements)
      .reduce((acc: number, element) => {
        const barsBuffer = getCanvasElementByType(element.type).getNumOfBarsBuffer({
          ...element,
          overlays: element.overlays!,
          // There has to be || because some e.g. ideas may have empty string periods
          period: element.period || '0',
          timeframe,
        })
        // If element period is edited to '', value will be parsed as NaN, and Math.max(NaN, {anyNumber}) will return NaN
        return Number.isNaN(barsBuffer) ? acc : Math.max(barsBuffer, acc)
      }, 0) + 1 // we add 1 to barsBuffer so we can ensure lines continuity on offscreen charts

  return maxNumOfBarsBuffer
}
