import { IconNameType } from '@finviz/website'

import ChartLayout from '../../models/chart_layout'

export enum DrawingTool {
  Arrow = 'arrow',
  Avwap = 'avwap',
  Brush = 'brush',
  Callout = 'callout',
  Curve = 'curve',
  ElliottWaves = 'elliott-wave',
  Ellipse = 'ellipse',
  FibonacciRetracements = 'fibonacci-retracements',
  HorizontalLine = 'horizontal-line',
  Measure = 'measure',
  Mouse = 'mouse',
  Pitchfork = 'pitchfork',
  Polygon = 'polygon',
  Position = 'position',
  Rectangle = 'rectangle',
  RotatedRectangle = 'rotated-rectangle',
  ScaledLine = 'scaled-line',
  ScaledText = 'scaled-text',
  Triangle = 'triangle',
  VerticalLine = 'vertical-line',
  Xabcd = 'xabcd',
}

export enum DrawingActions {
  AddNote = 'add-note',
  DeleteDrawings = 'delete-drawings',
  DeleteAutoSavedDrawings = 'delete-autosaved-drawings',
}

export enum DrawingSetting {
  PreserveDrawings = 'preserve-drawings',
  DrawingModeContinuous = 'drawing-mode-continuous',
  HideDrawings = 'hide-drawings',
  LockDrawings = 'lock-drawings',
}

export interface IToolBarProps {
  chartLayoutModel: ChartLayout
  isSidebar?: boolean
  isHorizontal?: boolean
  isMobileOverlay?: boolean
  /**
   * Current selected tool id
   */
  activeTool: DrawingTool
  /**
   * On tool item select callback
   */
  onActiveToolChange: (activeTool: DrawingTool, trigger?: 'button' | 'select' | 'group') => void
  /**
   * On drawing actions item select callback
   */
  onDrawingActionClick?: (actionId: DrawingActions) => void
  /**
   * On drawing settings item select callback
   */
  onDrawingSettingClick?: (settingId: DrawingSetting) => void
  /**
   * On drawing end callback
   */
  onDrawingEnd?: () => void
  /**
   * Current state of drawing settings
   */
  drawingSettings: Record<DrawingSetting, boolean>
  isAlternativeTheme?: boolean
  /**
   * Are tools disabled
   */
  areToolsDisabled?: boolean
}

export interface IToolbarItemProperties {
  icon: IconNameType
  iconActive?: IconNameType
  iconAction?: IconNameType
  title: string
  titleActive?: string
  titleAction?: string
}

export interface IToolbarDrawingTool extends IToolbarItemProperties {
  id: DrawingTool
}

export interface IToolbarDrawingAction extends IToolbarItemProperties {
  id: DrawingActions
}

export interface IToolbarDrawingSetting extends IToolbarItemProperties {
  id: DrawingSetting
}

export interface IToolbarDrawingToolItem {
  defaultItem: IToolbarDrawingTool
  toolGroup?: Array<IToolbarDrawingTool>
  toolGroupTitle?: string
}

export interface IToolbarDrawingActionItem {
  defaultItem: IToolbarDrawingAction
  isDisabled?: boolean
  disabledTooltip?: string
}

export interface IToolbarDrawingsSettingItem {
  defaultItem: IToolbarDrawingSetting
  isDisabled?: boolean
  isInAction?: boolean
  disabledTooltip?: string
}
