import { SMACalculation } from '../indicator-calculation/sma'
import { drawInVisibleArea } from '../utils/draw_in_visible_area'
import { Attrs, SMAConfig } from './configs/sma'
import Overlay from './overlay'

class SMA extends Overlay {
  static config = SMAConfig
  declare smaCalculation: SMACalculation

  set(obj: Partial<Attrs>) {
    super.set(obj)
    const { period } = obj
    if (typeof period === 'string') {
      this.attrs.period = parseInt(period, 10)
      this.trigger('change')
    }
    return this
  }

  renderContent(context: CanvasRenderingContext2D) {
    super.renderContent()
    const { leftOffset, width } = this.model.chart()
    const period = Math.min(this.attrs.period, this.data.close.length - 1)
    context.beginPath()
    context.translate(0.5, 0.5)
    context.set('lineWidth', 1)
    context.set('strokeStyle', this.attrs.color)

    if (this.isComputeNecessary()) {
      this.smaCalculation = new SMACalculation({
        quote: this.data,
        options: { period },
      })

      this.smaCalculation.calculate()
    }

    drawInVisibleArea({
      fromIndexOffset: period - 1,
      leftOffset,
      width,
      quote: this.data,
      paneModel: this.model,
      drawBarCallback: (i, x) => {
        context.lineTo(x, Math.round(this.fy(this.smaCalculation.calculatedValues.sma[i])))
      },
    })

    context.translate(-0.5, -0.5)
    context.stroke()
  }

  getModalConfig() {
    const options = {
      period: {
        type: 'number',
        label: 'Period',
        name: 'period',
        value: this.attrs.period ?? 5,
        required: true,
        min: 1,
        max: 999999,
      },
      color: {
        type: 'color',
        label: 'Color',
        name: 'color',
        value: this.attrs.color ?? this.getFreeColor(),
      },
    }

    return {
      title: this.config.label,
      inputs: this.config.inputsOrder.map((item) => options[item as keyof Attrs]),
      inputsErrorMessages: {
        period: `${options.period.label} must be a whole number between ${options.period.min} and ${options.period.max}`,
      },
    }
  }

  getIsValid(key: string) {
    switch (key) {
      case 'period':
        return this.getIsNumberInputValid({ key })
      case 'color':
        return !!this.attrs[key] // use real color validation, we got isValidHexColor but it might be also RGBA
      default:
        return false
    }
  }
}

SMA.prototype.defaults = { color: '#ffa75f' }

export default SMA
